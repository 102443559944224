import axios from "axios";
import axiosInstance from "../../utlis/axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Pagination, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ftpEdit } from "../../features/editSlice";
import PageTitle from "../../hooks/PageTitle";
import AddFTPModal from "../modal/AddFTPModal";
import DeleteModal from "../modal/DeleteModal";
import EditFTPModal from "../modal/EditFTPModal";

const AddCompany = () => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [ftpList, setFtpList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCounte] = useState(1);
  const [limit, setLimit] = useState(5);
  const dispatch = useDispatch();

  // Delete Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`/deliveryRoute/ftp/list?page=${page}&limit=${limit}`)
      .then((res) => {
        console.log(res.data)
        setFtpList(res.data.ftpServers);
        setPageCounte(res.data.pageCount);
      });
  }, [refetch, page, limit]);

  const handleDelet = (id, name) => {
    setName(name);
    setId(id);
    handleShow();
  };

  const deleteConfirm = () => {
    axiosInstance
      .delete(`/deliveryRoute/ftp/${id}`)
      .then((res) => {
        if (res.data.success) {
            toast.success(`FTP "${res.data._id}" deleted successfully`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRefetch(!refetch);
          handleClose();
        }
      });
  };

  const handleEdit = (ftp) => {
    setModalShowEdit(true);
    dispatch(ftpEdit(ftp));
  };

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setPage(newPage);
    }
  }
  let startPage = Math.max(page - 1, 1);
  let endPage = Math.min(startPage + 2, pageCount);

  if (endPage - startPage < 2) {
    startPage = Math.max(endPage - 2, 1);
  }

  const handleLimit = (e) => {
    setLimit(parseInt(e.target.value));
  };

  return (
    <div className="login_form dashboard_page" style={{ height: '100%', width: '100%'}}>
      <PageTitle title="Delivery Server" />

      <div className="d-flex justify-content-between dashboard_item align-self-stretch">
        <h3>Delivery Server</h3>
        <Button
          onClick={() => setModalShow(true)}
          variant="primary"
          className="primary_bg"
        >
          Add FTP
        </Button>
      </div>
      <div className="table-responsive" style={{height: '60%', width: '100%', overflow: 'auto'}}>
      <Table className="scanJob_table" bordered size="lg">
        <thead>
          <tr>
            <th>server id</th>
            <th>serverName</th>
            <th>serverAddress</th>
            <th>serverProtocol</th>
            <th>User Name</th>
            <th>serverEnabled</th>
            <th>serverRoot</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {ftpList &&
            ftpList?.map((ftp, index) => {
              console.log(ftp)
              console.log(ftp?.serverEnabled)
              return (
                <tr key={ftp._id} style={{textAlign: 'center'}}>
                  <td>{ftp._id}</td>
                  <td>{ftp.serverName}</td>
                  <td>{ftp.serverAddress}</td>
                  <td>{ftp.serverProtocol}</td>
                  <td>{ftp.serverCredentials.userName}</td>
                  <td>{ftp.serverEnabled === true ? '✅ ' : '⛔'}</td>
                  <td>{ftp.serverRoot}</td>  
                  <td>
                    <span role="button" onClick={() => handleEdit(ftp)}>
                      Edit
                    </span>
                    &nbsp;&nbsp;
                    <span
                      role="button"
                      onClick={() =>
                        handleDelet(ftp._id, ftp.serverName)
                      }
                      className="text-danger"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      </div>

      {/* MOdals */}
      <AddFTPModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <EditFTPModal
        refetch={refetch}
        setRefetch={setRefetch}
        modalShow={modalShowEdit}
        setModalShow={setModalShowEdit}
      />
      <DeleteModal
        name={name}
        show={show}
        handleClose={handleClose}
        deleteConfirm={deleteConfirm}
      />
      <div className="pagination_product">
        <Form.Select
          disabled={ftpList?.length < 5}
          onChange={handleLimit}
          className="limit_select-lg"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="25">25</option>
          <option value="35">35</option>
          <option value="50">50</option>
        </Form.Select>
        <Pagination size="lg">
          <Pagination.First
            onClick={() => handlePageClick(page - 1)}
            disabled={page === 1}
          />
          {[...Array(endPage - startPage + 1)].map((_, index) => {
            const pageNumber = startPage + index;
            return (
              <Pagination.Item
                key={pageNumber}
                active={pageNumber === page}
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}
          <Pagination.Last
            onClick={() => handlePageClick(page + 1)}
            disabled={page === pageCount}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default AddCompany;
