import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NavBar from "./components/navbar/NavBar";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/login/Login";
import BodyTitle from "./components/bodyTitle/BodyTitle";
import RequireAuth from "./hooks/RequireAuth";
import HomePage from "./components/homePage/HomePage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./components/dashboard/Dashboard";
import AddUser from "./components/dashboard/AddUser";
import AddCompany from "./components/dashboard/AddCompany";
import AddFTP from "./components/dashboard/AddFTP";
import CheckUserAdministrators from "./hooks/CheckUserAdministrators";
import ApiKey from "./components/dashboard/ApiKey";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <NavBar />
      {location.pathname === '/faq' || location.pathname.split('/')[1] === 'renderjobs' ? '' : <BodyTitle />}

      <Routes>
        <Route path="/" element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>

        } />


        <Route path="/administration" element={
          <RequireAuth>
            <CheckUserAdministrators>
              <Dashboard />
            </CheckUserAdministrators>
          </RequireAuth>}>

          <Route path="company-new" element={
            <RequireAuth>
              <AddCompany />
            </RequireAuth>
          } />

          <Route path="ftp-new" element={
            <RequireAuth>
              <AddFTP />
            </RequireAuth>
          } />

          <Route path="apikey-new" element={
            <RequireAuth>
              <ApiKey />
            </RequireAuth>
          } />

          <Route index element={<AddUser />} />
        </Route>

        <Route path="/login" element={

          <Login />
        } />

      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
