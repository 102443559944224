import axios from "axios";
import axiosInstance from "../../utlis/axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const AddCompanyModal = ({ setModalShow, modalShow, refetch, setRefetch }) => {
  const { REACT_APP_EXPRESS_BACKEND_URL } = process.env;
  const [companyname, setName] = useState("");
  const [ftpList, setFtpList] = useState([]);
  const [ftpServer, setFtpServer] = useState("");
  const [billingemail, setEmail] = useState("");
  const [graphContainerKey, setGraphKey] = useState("");
  useEffect(() => {
    axiosInstance
      .get(`/deliveryRoute/ftp/list`)
      .then((res) => setFtpList(res.data.ftpServers));
  }, []);

  const HandleAddCompany = async (e) => {
    e.preventDefault();
    const companyInfo = { companyname, billingemail, ftpServer };
    if (graphContainerKey != ""){
      companyInfo.graphContainerKey = graphContainerKey
    }
    try {
      await axiosInstance
        .post(`/companyroute/new`, companyInfo)
        .then((res) => {
          if (res.data._id) {
            toast.success(
              `Company "${res.data.companyname}" added successfully`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            setName("");
            setEmail("");
            setGraphKey("");
            setFtpServer("");
            setModalShow();
            setRefetch(!refetch);
          }
          if (res.data.code === 11000) {
            toast.error("Email already exists", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={setModalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleAddCompany}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Control
              required
              type="text"
              value={companyname}
              placeholder="Company name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="email"
              value={billingemail}
              className="email_filed"
              placeholder="Company email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicGraphKey">
            <Form.Control
              type="text"
              value={graphContainerKey}
              placeholder="GraphContainer Key"
              onChange={(e) => setGraphKey(e.target.value)}
            />
          </Form.Group>
          <Form.Select
            className="mb-3"
            value={ftpServer}
            onChange={(e) => setFtpServer(e.target.value)}
            aria-label="FTP Server Selection"
          >
            <option value="">Select FTP Server</option>
            {ftpList.map((ftp) => (
              <option key={ftp._id} value={ftp._id}>
                {ftp.serverName}
              </option>
            ))}
          </Form.Select>
          <Button variant="primary" type="submit">
            Add Company
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCompanyModal;
