import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editCompany: {},
  editapi: {},
  editUser: {},
  editFtp: {}
}

const editSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyEdit: (state, action) => {
      state.editCompany = action.payload
    },
    ftpEdit: (state, action) => {
      state.editFtp = action.payload
    },
    apiEdit: (state, action) => {
      state.editapi = action.payload
    },
    userEdit: (state, action) => {
      state.editUser = action.payload
    },
  }

})

export const { companyEdit, ftpEdit, apiEdit, userEdit } = editSlice.actions;
export default editSlice.reducer