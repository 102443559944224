import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userLoggedIn, userLoggedOut } from "../features/authSlice";

function RequireAuth({ children }) {
  const goLogin = useNavigate();
  const { REACT_APP_SESSION_TIMEOUT_MINUTES, REACT_APP_EXPRESS_BACKEND_URL } =
    process.env;
  const dispatch = useDispatch();
  let location = useLocation();
  const { accessToken, user, timestamp } = useSelector((state) => state.auth);

  moment.fn.fromNow = function (a) {
    var duration = moment().diff(this, "minutes");
    return duration;
  };

  if (accessToken && user) {
    // Session-Timeout-Überprüfung
    if (moment(timestamp).fromNow() >= REACT_APP_SESSION_TIMEOUT_MINUTES) {
      dispatch(userLoggedOut());
      localStorage.removeItem("accessToken");
      toast.warn(`User Session expired for ${REACT_APP_SESSION_TIMEOUT_MINUTES} minutes`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
  } else {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      const auth = JSON.parse(storedToken);
  
      axios.get(`/authroute/verifyjwt`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?._id) {
          if (moment(auth?.timestamp).fromNow() >= REACT_APP_SESSION_TIMEOUT_MINUTES) {
            dispatch(userLoggedOut());
            localStorage.removeItem("accessToken");
            return <Navigate to="/login" state={{ from: location }} replace />;
          } else {
            dispatch(userLoggedIn({
              accessToken: auth.accessToken,
              refreshToken: auth.refreshToken,
              user: auth.user,
              timestamp: auth.timestamp,
            }));
            return children;
          }
        } else {
          dispatch(userLoggedOut());
          localStorage.removeItem("accessToken");
          return goLogin("/login", {
            state: { from: location },
            replace: true,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(userLoggedOut());
          localStorage.removeItem("accessToken");
          return goLogin("/login", {
            state: { from: location },
            replace: true,
          });
        }
      });
    } else {
      dispatch(userLoggedOut());
      localStorage.removeItem("accessToken");
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
}

export default RequireAuth;
