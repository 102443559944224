import { configureStore } from "@reduxjs/toolkit";
import { verifyJwtSlice } from "../features/api/verifyJwtSlice";
import authSliceReducer from "../features/authSlice";
import editSliceReducer from "../features/editSlice";

export const store = configureStore({
  reducer: {
    [verifyJwtSlice.reducerPath]: verifyJwtSlice.reducer,
    auth: authSliceReducer,
    company: editSliceReducer,
  },
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(
      verifyJwtSlice.middleware
    ),
});
