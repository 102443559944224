import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userLoggedIn } from "../../features/authSlice";
import PageTitle from "../../hooks/PageTitle";
import "./Login.css";

const Login = () => {
  const token = localStorage.getItem("accessToken");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [user, setUser] = useState([]);
  const navigateToHome = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const auth = JSON.parse(token);
    if (auth?.accessToken && auth?.user) {
      navigateToHome("/");
    }
  }, [token, navigateToHome]);

  const handleLoginResponse = (response) => {
    if (response?.data?.access_token && response?.data?.refresh_token) {
      const { access_token, refresh_token, user } = response.data;

      // Speichern der Tokens und Benutzerinformationen in localStorage
      localStorage.setItem("accessToken", JSON.stringify({
        accessToken: access_token,
        refreshToken: refresh_token,
        user: user,
        timestamp: new Date().toISOString()
      }));

      // Benutzer im Redux-Store anmelden
      dispatch(userLoggedIn({
        accessToken: access_token,
        refreshToken: refresh_token,
        user: user,
        timestamp: new Date().toISOString()
      }));

      // Weiterleitung zur Startseite
      navigateToHome(from, { replace: true });
    }
  };

  const HandleLogin = async (e) => {
    e.preventDefault();
    const loginInfo = { username: email, password };
    try {
      const response = await axios.post(`/authroute/passport`, loginInfo);
      handleLoginResponse(response);
    } catch (error) {
      if (error.response && error.response.status) {
        toast.error("User or password is incorrect", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <div className="login_form">
      <PageTitle title="Login" />
      <Form onSubmit={HandleLogin}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="mb-3">Sign in</Form.Label>
          <Form.Control
            required
            className="email_filed"
            type="text"
            value={email}
            placeholder="my@email.add"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            required
            type="password"
            value={password}
            placeholder="••••••••"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Log in
        </Button>
      </Form>
    </div>
  );
};

export default Login;
