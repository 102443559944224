import axios from 'axios'

const token = JSON.parse(localStorage.getItem("accessToken"))?.accessToken;
// console.log("Here is token!", token);

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  }
});

export default axiosInstance