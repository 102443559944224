import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const verifyJwtSlice = createApi({
  reducerPath: "verifyjwt",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_EXPRESS_BACKEND_URL,
  }),
  endpoints: (builder) => ({
    verifyjwtToken: builder.mutation({
      query: (accessToken) => ({
        url: "/authroute/verifyjwt",
        method: "GET",
        headers: {
          'authorization': `Bearer ${accessToken}`
        }
      }),
    }),
  }),
});

export const { useVerifyjwtTokenMutation } = verifyJwtSlice;
