import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: undefined,
  profile: undefined,
  user: undefined,
  timestamp: undefined
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.accessToken = action.payload.accessToken
      state.profile = action.payload.profile
      state.user = action.payload.user
      state.timestamp = action.payload.timestamp
    },
    userLoggedOut: (state, action) => {
      state.accessToken = undefined
      state.profile = undefined
      state.user = undefined
      state.timestamp = undefined
    },
  }

})

export const { userLoggedIn, userLoggedOut } = authSlice.actions;
export default authSlice.reducer