import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";

function ShowConfigModal({modalShow, setModalShow, config, title}) {
    console.log(modalShow)
    console.log(title)
    console.log(config)
  return (
        <Modal
          show={modalShow}
          onHide={setModalShow}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {title === 'freeCameraConfig' && (
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', wordBreak: 'break-all'}}>
                    <div style={{whiteSpace:'none'}}>
                        <span style={{fontWeight:'bold'}}>Camera Position:</span> {config[0].camera.posX}, {config[0].camera.posY}, {config[0].camera.posZ}
                    </div>
                    <div style={{whiteSpace:'none'}}>
                    <span style={{fontWeight:'bold'}}>LookAt Target (X|Y|Z):</span> {config[0].camera.targetX}, {config[0].camera.targetY}, {config[0].camera.targetZ}
                    </div>
                    <div>
                    <span style={{fontWeight:'bold'}}>Image Filetype:</span> {config[0].outputFileType}
                    </div>
                    <div>
                    <span style={{fontWeight:'bold'}}>Resolution (pixel):</span> {config[0].resolution.width}X{config[0].resolution.height}
                    </div>
                </div>
            )}
            {title === 'setCameraConfig' && (
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start',  alignItems:'flex-start', wordBreak: 'break-all'}}>
                    <div>
                    <span style={{fontWeight:'bold'}}>Selected Cams:</span> {config[0].selectedCams}
                    </div>
                    <div>
                    <span style={{fontWeight:'bold'}}>Image Filetype:</span> {config[0].outputFileType}
                    </div>
                    <div>
                    <span style={{fontWeight:'bold'}}>Resolution (pixel):</span> {config[0].resolution.width}X{config[0].resolution.height}
                    </div>
                </div>
            )}
            {title === 'interiorConfig' && (
                <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start',  alignItems:'flex-start', wordBreak: 'break-all'}}>
                <div>
                {config.selectedAssets && config.selectedAssets.length > 0 && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Selected Assets:</span> 
                    {config.selectedAssets.map((value, index) => {
                      return (index === config.selectedAssets.length - 1)
                        ? (value.name || '').replace(/,/g, '')
                        : (value.name || '').replace(/,/g, '') + ', ';
                    }).join('')}
                  </>
                )}
                </div>
                <div>
                {config.selectedAssets && config.selectedAssets.length > 0 && (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Selected Cams:</span> 
                    {config.selectedCams.map((value, index) => {
                      return (index === config.selectedCams.length - 1)
                        ? (value || '').replace(/,/g, '')
                        : (value || '').replace(/,/g, '') + ', ';
                    }).join('')}
                  </>
                )}
                </div>
                <div>
                <span style={{fontWeight:'bold'}}>Image Filetype:</span> {config.outputFileType}
                </div>
                <div>
                <span style={{fontWeight:'bold'}}>Resolution (pixel):</span> {config.resolution.width}X{config.resolution.height}
                </div>
            </div>
            )}
          </Modal.Body>
    </Modal>
  )
}

export default ShowConfigModal